import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { lucid, lucid_user, getWalletInfo } from '../../cardano/market-api/helper'
import Wallet from '../../cardano/wallet'
import { setWalletLoading, walletCardanoConnected, walletCardanoDisconnected } from '../features/walletSlice';

const baseUrl = ``

export const availableWallets = (callback) => async (dispatch) => {
    callback({
        success: true,
        wallets: await Wallet.getAvailableWallets(),
        msg: "",
    });
};

export const disconnectCardanoWallet = (provider, callback) => async (dispatch) => {
    try {
        if (await Wallet.enable(provider)) {
            localStorage.removeItem("wallet");
            dispatch(walletCardanoDisconnected());
            callback({
                success: true,
            });
        } else {
            callback({
                success: false,
                msg: "Failed to disconnect wallet"
            });
        }
    } catch (error) {
        console.error('Wallet disconnection error:', error);
        callback({
            success: false,
            msg: error.message || "Failed to disconnect wallet"
        });
    }
};

export const connectCardanoWallet = (provider, callback) => async (dispatch) => {
    dispatch(setWalletLoading(true));

    try {
        let wallet_provider = await window.cardano[provider].enable();

        if (wallet_provider) {
            await lucid_user.selectWallet(wallet_provider);

            const isConnector = wallet_provider._providerName && wallet_provider._providerName !== provider;
            
            await getWalletInfo();

            const walletInfo = {
                wallet: provider,
                connectedThrough: isConnector ? wallet_provider._providerName : null
            };
            localStorage.setItem("wallet", JSON.stringify(walletInfo));

            const connectedWallet = {
                wallet: provider,
                address: await lucid_user.wallet.address(),
                connectedThrough: isConnector ? wallet_provider._providerName : null
            };

            dispatch(walletCardanoConnected(connectedWallet));
            callback({
                success: true,
                data: connectedWallet,
            });
        } else {
            dispatch(setWalletLoading(false));
            callback({
                success: false,
                msg: "Please switch your Wallet's Network.",
            });
        }
    } catch (error) {
        console.error('Wallet connection error:', error);
        dispatch(setWalletLoading(false));
        callback({
            success: false,
            msg: error.message || "Failed to connect wallet",
        });
    }
};

export const walletApi = createApi({
    reducerPath: 'walletApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['Wallet'],
    endpoints: (builder) => ({
    }),
});

export const {
} = walletApi;