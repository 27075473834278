import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePostItem from "../components/HomePostItem";

import { useGetPopularUsersQuery, useGetFeaturedUsersQuery } from "../redux/apis/userApi";

import { devmode } from "../config";

const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);

    useGetPopularUsersQuery();
    useGetFeaturedUsersQuery();

    useEffect(() => {
        if (devmode) console.log("state:", state_wallet)
    }, [state_wallet.connected]);

    return (
        <div className="w-full h-auto flex flex-col gap-[10px] lg:gap-[60px] pb-[200px] lg:pb-[100px] bg-main_bg">
            {/* Banner Section - Absolute positioned at the very top */}
            <div className="absolute top-0 left-0 w-full h-[600px]">
                <img 
                    src="/images/stk_banner_bg.png" 
                    alt="" 
                    className="w-full h-full object-cover" 
                />
            </div>
            
            {/* Content Container - With proper spacing from top */}
            <div className="relative w-full max-w-7xl mx-auto px-4 flex flex-col items-center gap-[40px] pt-[140px] z-10">
                <img 
                    src="/images/stk_logo.png" 
                    alt="StreamKey Logo" 
                    className="w-[300px] lg:w-[400px] object-contain drop-shadow-[0_0_15px_rgba(59,130,246,0.2)] hover:drop-shadow-[0_0_25px_rgba(59,130,246,0.3)] transition-all duration-700 filter brightness-110 hover:brightness-125" 
                />
                
                <h1 className="text-[40px] md:text-[64px] font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-teal-500 via-blue-500 to-indigo-500 animate-gradient text-center font-poppins shadow-lg">
                    Welcome to StreamKey!
                </h1>

                <p className="text-sm md:text-base text-white/80 text-center mt-4">
                    Join a Thriving Community of Creators. Share Your Passion and Connect with NFT Holders Worldwide.
                </p>

                <div className="flex flex-col md:flex-row gap-[20px] w-full max-w-xl justify-center">
                    <button 
                        onClick={(e) => { state_wallet.connected ? navigate("/create") : toast("Connect Wallet First!") }}
                        className="w-full md:w-auto text-[15px] md:text-[20px] bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 rounded-full px-8 py-3 font-medium text-white transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-blue-500/25 active:scale-[0.98] border border-white/20"
                    >
                        Create Your StreamKey
                    </button>

                    {state_wallet.connected && state_user.user ? (
                        <a href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user.Code}`} className="w-full md:w-auto">
                            <button className="w-full text-[15px] md:text-[20px] bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-500 hover:to-blue-500 rounded-full px-8 py-3 font-medium text-white transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-purple-500/25 active:scale-[0.98] border border-white/20">
                                Explore Your Assets
                            </button>
                        </a>
                    ) : (
                        <button 
                            onClick={(e) => { toast("Connect Wallet First!") }}
                            className="w-full md:w-auto text-[15px] md:text-[20px] bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-500 hover:to-blue-500 rounded-full px-8 py-3 font-medium text-white transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-purple-500/25 active:scale-[0.98] border border-white/20"
                        >
                            Explore Your Assets
                        </button>
                    )}
                </div>
            </div>

            <style jsx>{`
                @keyframes gradient {
                    0% { background-position: 0% 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0% 50%; }
                }
                .animate-gradient {
                    background-size: 200% auto;
                    animation: gradient 8s linear infinite;
                }
            `}</style>
            <div className="px-[10px] lg:px-[200px] w-full py-[10px] flex flex-col gap-[30px] items-center bg-gradient-to-b from-transparent via-white/5 to-transparent rounded-lg shadow-md">
                <div className="text-[40px] md:text-[64px] font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-aqua-400 via-blue-300 to-white animate-gradient text-center font-poppins shadow-lg">
                    Featured Streamkeys
                </div>
                <div className="w-full px-[20px] flex flex-row gap-[20px] items-center">
                    <div className="relative w-full h-full rounded-xl overflow-hidden shadow-xl p-[10px] bg-white/10 backdrop-blur-sm">
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-[10px] gap-y-[10px]">
                            {state_user.popular_users.map((popular_user) => {
                                return (
                                    <HomePostItem asset={popular_user} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="px-[10px] lg:px-[200px] w-full py-[20px] px-[5px] flex flex-col gap-[100px]">
                <div className="text-[30px] lg:text-[40px] font-bold text-white text-center">Featured Contents</div>
                <div className="w-full px-[20px] flex flex-row gap-[20px] items-center">
                    <div className="relative w-full h-full rounded-xl overflow-hidden shadow-xl p-[40px] bg-white/10 backdrop-blur-sm">
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-y-[20px]">
                            {state_user.featured_users.map((featured_user) => {
                                return (
                                    <HomePostItem asset={featured_user} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    );
};

export default Home;