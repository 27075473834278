import { createSlice } from '@reduxjs/toolkit';

import { devmode } from "../../config";

const initialState = {
  connected: false,
  loading: false,
  wallet: null,
  connectedThrough: null,
  address: null,
  editingId: 0,
  streamkey_nfts: [],
  lovelace_amount: 0,
  policy_ids: [],
}

const walletSlice = createSlice({
  name: 'walletSlice',
  initialState,
  reducers: {
    walletCardanoConnected: (state, action) => {
      if (devmode) console.log("walletCardanoConnected:", action.payload);
      state.connected = true;
      state.loading = false;
      state.wallet = action.payload.wallet;
      state.connectedThrough = action.payload.connectedThrough;
      state.address = action.payload.address;
    },
    walletCardanoDisconnected: (state) => {
      return { ...initialState };
    },
    setWalletLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEditingId: (state, action) => {
      if (devmode) console.log("setEditingId", action.payload);
      state.editingId = action.payload;
    },
    setWalletAssetInfo: (state, action) => {
      if (devmode) console.log("setWalletAssetInfo", action.payload);
      state.streamkey_nfts = action.payload.streamkey_nfts;
      state.lovelace_amount = action.payload.lovelace_amount;
      state.policy_ids = action.payload.policy_ids;
    }
  },
})

export default walletSlice.reducer

export const { walletCardanoConnected, walletCardanoDisconnected, setWalletLoading, setEditingId, setWalletAssetInfo } = walletSlice.actions