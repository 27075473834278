import React from 'react';
import { Edit, Check } from "@mui/icons-material";
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { InfoOutlined } from "@mui/icons-material";

const FormField = ({
    label,
    value,
    isEditing,
    onEdit,
    onSave,
    onChange,
    multiline = false,
    error = false,
    errorText = '',
    tooltip = '',
    disabled = false,
    children = null
}) => {
    // Store the original value when editing starts
    const [originalValue, setOriginalValue] = React.useState(value);

    // Update original value when editing starts
    React.useEffect(() => {
        if (isEditing) {
            setOriginalValue(value);
        }
    }, [isEditing]);

    const handleCancel = () => {
        // Reset to original value
        onChange(originalValue);
        // Close edit mode by calling onEdit
        onEdit();
    };

    const tooltipId = `tooltip-${label.toLowerCase().replace(/\s+/g, '-')}`;

    return (
        <div className="space-y-2">
            <div className="flex items-center gap-2">
                <label className="text-white/90 font-medium">{label}</label>
                {tooltip && (
                    <div 
                        className="text-white/40 hover:text-white/60 transition-colors cursor-help"
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={tooltip}
                    >
                        <InfoOutlined sx={{ fontSize: "16px" }} />
                    </div>
                )}
            </div>

            {isEditing ? (
                <div className="space-y-4">
                    {children || (
                        <textarea
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            className="w-full bg-[#1a1a1a] border border-white/10 rounded-lg p-3 text-white resize-none focus:outline-none focus:border-blue-500"
                            rows={multiline ? 4 : 2}
                            disabled={disabled}
                        />
                    )}
                    <div className="flex justify-end gap-2">
                        <button 
                            onClick={handleCancel}
                            className="px-4 py-2 text-white/60 hover:text-white transition-colors"
                        >
                        </button>
                        <button 
                            onClick={onSave}
                            disabled={disabled || error}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                                disabled || error
                                    ? 'bg-green-600/10 border border-green-500/30 text-white/30 cursor-not-allowed'
                                    : 'bg-green-600/20 border border-green-500 text-white hover:bg-green-600/30'
                            }`}
                        >
                            <Check sx={{ fontSize: "20px" }} />
                            <span>Save</span>
                        </button>
                    </div>
                    {error && errorText && (
                        <p className="text-red-500 text-sm mt-1">{errorText}</p>
                    )}
                </div>
            ) : (
                <div className="flex justify-between items-start gap-4">
                    {value === "Not set" ? (
                        <p className="text-white/40 italic">Not set</p>
                    ) : (
                        <div 
                            className="w-full overflow-hidden text-white/90 prose prose-invert max-w-none"
                            dangerouslySetInnerHTML={{ __html: value }}
                        />
                    )}
                    <button 
                        onClick={onEdit}
                        disabled={disabled}
                        className={`shrink-0 flex items-center gap-1 px-2 py-1 rounded-lg transition-colors ${
                            disabled
                                ? 'bg-blue-600/10 border border-blue-500/30 text-white/30 cursor-not-allowed'
                                : 'bg-blue-600/20 border border-blue-500 text-white hover:bg-blue-600/30'
                        }`}
                    >
                        <Edit sx={{ fontSize: "16px" }} />
                        <span>Edit</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default FormField;