export const API_CONFIG = {
    baseUrl: process.env.REACT_APP_STREAMKEY_API_URL,
    endpoints: {
        checkCode: 'user/check-code',
        content: 'content/address',
        user: 'user',
        upload: 'content/upload',
        uploads: 'uploads',
        wallet: 'wallet/info'
    }
};

// Helper function for debugging API calls
const debugApiCall = (url, method = 'GET') => {
    console.log(`API ${method} Request to:`, url);
};

export const createApiUrl = (endpoint, param = '') => {
    const baseUrl = process.env.REACT_APP_STREAMKEY_API_URL.replace(/\/$/, '');
    const endpointPath = API_CONFIG.endpoints[endpoint] || endpoint;
    const cleanEndpoint = endpointPath.replace(/^\/?(api\/)?/, '');
    const apiPrefix = baseUrl.includes('/api') ? '' : '/api';
    const fullEndpoint = `${apiPrefix}/${cleanEndpoint}`;
    const cleanParam = param ? `/${encodeURIComponent(param)}`.replace(/\/+/g, '/') : '';
    
    const finalUrl = `${baseUrl}${fullEndpoint}${cleanParam}`;
    console.log('Generated API URL:', finalUrl);
    return finalUrl;
};

export const createFileUrl = (path) => {
    const baseUrl = process.env.REACT_APP_STREAMKEY_API_URL.replace(/\/$/, '');
    const cleanPath = path.replace(/^(\/|api\/)+/, '');
    return `${baseUrl}/${cleanPath}`;
};
