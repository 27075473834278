import { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ZoomIn, ZoomOut, NavigateNext, NavigateBefore, Bookmark, Search } from '@mui/icons-material';
import { devmode } from "../config";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const PdfViewer = ({ url, isThumbnail, isFullscreen }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(isFullscreen ? 1.5 : 1);
  const [searchTerm, setSearchTerm] = useState('');
  const [bookmarks, setBookmarks] = useState([]);
  const viewerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => {
      const nextPage = prevPageNumber + offset;
      return nextPage >= 1 && nextPage <= numPages ? nextPage : prevPageNumber;
    });
  };

  const changeScale = (delta) => {
    setScale(prevScale => {
      const newScale = prevScale + delta;
      return newScale >= 0.5 && newScale <= 3 ? newScale : prevScale;
    });
  };

  const toggleBookmark = (page) => {
    setBookmarks((prev) =>
      prev.includes(page) ? prev.filter((p) => p !== page) : [...prev, page]
    );
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    // Implement search logic here, updating searchResults with page numbers or highlights
  };

  useEffect(() => {
    if (devmode) console.log("PdfViewer ---> ", url, isThumbnail);
  }, [url, isThumbnail]);

  if (isThumbnail) {
    return (
      <div className="h-full w-full">
        <Document className="h-full overflow-hidden" file={url} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          <Page 
            className="h-full w-full !bg-transparent flex flex-row justify-center" 
            pageNumber={1} 
            renderTextLayer={false} 
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    );
  }

  return (
    <div ref={viewerRef} className="h-full w-full flex flex-col relative">
      {/* PDF Content */}
      <div className="flex-1 overflow-auto bg-[#2a2a2a]">
        <Document 
          className="min-h-full" 
          file={url} 
          onLoadSuccess={onDocumentLoadSuccess} 
          options={options}
        >
          <div className="flex flex-col items-center gap-4 p-4">
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              scale={scale}
              className="!bg-white rounded-lg shadow-xl"
              renderTextLayer={true}
              renderAnnotationLayer={true}
            />
          </div>
        </Document>
      </div>

      {/* Floating Toolbar */}
      <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between p-2 bg-[#1a1a1a] border-t border-white/10">
        <div className="flex items-center gap-2">
          <button
            onClick={() => changePage(-1)}
            disabled={pageNumber <= 1}
            className="p-1 text-white/80 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <NavigateBefore />
          </button>
          <span className="text-white/90 text-sm">
            Page {pageNumber} of {numPages}
          </span>
          <button
            onClick={() => changePage(1)}
            disabled={pageNumber >= numPages}
            className="p-1 text-white/80 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <NavigateNext />
          </button>
        </div>
        
        <div className="flex items-center gap-2">
          <button
            onClick={() => changeScale(-0.25)}
            className="p-1 text-white/80 hover:text-white"
            disabled={scale <= 0.5}
          >
            <ZoomOut />
          </button>
          <span className="text-white/90 text-sm">{Math.round(scale * 100)}%</span>
          <button
            onClick={() => changeScale(0.25)}
            className="p-1 text-white/80 hover:text-white"
            disabled={scale >= 3}
          >
            <ZoomIn />
          </button>
          <button
            onClick={() => toggleBookmark(pageNumber)}
            className={`p-1 text-white/80 hover:text-white ${
              bookmarks.includes(pageNumber) ? 'text-yellow-500' : ''
            }`}
          >
            <Bookmark />
          </button>
          <button
            onClick={() => handleSearch(searchTerm)}
            className="p-1 text-white/80 hover:text-white"
          >
            <Search />
          </button>
        </div>
      </div>

      {/* Bookmarks List */}
      {bookmarks.length > 0 && (
        <div className="absolute top-0 right-0 m-4 p-2 bg-[#1a1a1a] rounded-lg shadow-lg">
          <h3 className="text-white text-sm mb-2">Bookmarks</h3>
          <ul className="text-white text-xs">
            {bookmarks.map((page, index) => (
              <li key={index} className="cursor-pointer hover:underline" onClick={() => setPageNumber(page)}>
                Page {page}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;