import { createApiUrl } from '../config/api.config';

export const safeUserData = (user) => ({
    ID: user?.ID || '',
    Address: user?.Address || '',
    Code: user?.Code || '',
    Banner: user?.Banner || '',
    Badge: user?.Badge || '',
    Title: user?.Title || '',
    CreatorName: user?.CreatorName || '',
    Description: user?.Description || '',
    IsPopular: user?.IsPopular || false,
    NftName: user?.NftName || '',
    PolicyId: user?.PolicyId || '',
    BuyLink: user?.BuyLink || ''
});

export const createSafeFormData = (user) => {
    const formData = new FormData();
    
    if (user) {
        formData.append("id", user.ID || '');
        formData.append("address", user.Address || '');
        formData.append("code", user.Code || '');
        formData.append("banner", user.Banner || '');
        formData.append("badge", user.Badge || '');
        formData.append("title", user.Title || '');
        formData.append("creator_name", user.CreatorName || '');
        formData.append("description", user.Description || '');
        formData.append("is_popular", user.IsPopular || false);
        formData.append("nft_name", user.NftName || '');
        formData.append("policy_id", user.PolicyId || '');
        formData.append("buy_link", user.BuyLink || '');
        formData.append("page_code", user.Code || '');
    }
    
    return formData;
};

export const formatStreamKeyCode = (code) => {
    return code.toLowerCase().replace(/[^a-z0-9]/g, '');
};

export const generateUniqueCode = (address) => {
    const timestamp = Date.now().toString(36);
    const walletSuffix = address.slice(-8);
    return `${walletSuffix}${timestamp}`.toLowerCase();
};

export const validateStreamKeyUrl = async (code, currentCode) => {
    const formattedCode = formatStreamKeyCode(code);
    
    if (!formattedCode) {
        throw new Error("StreamKey URL cannot be empty");
    }

    if (formattedCode === currentCode?.toLowerCase()) {
        return formattedCode;
    }

    try {
        const response = await fetch(createApiUrl('checkCode', formattedCode), {
            headers: {
                'Accept': 'application/json'
            }
        });
        
        if (!response.ok) {
            throw new Error(`Failed to validate StreamKey URL: ${response.status}`);
        }

        const data = await response.json();
        
        if (!data.valid) {
            throw new Error(data.message || "Failed to validate StreamKey URL");
        }
        
        if (data.exists) {
            throw new Error(data.message);
        }

        return formattedCode;
    } catch (error) {
        console.error("StreamKey URL validation error:", error);
        throw new Error(error.message || "Failed to check StreamKey URL availability");
    }
}; 