import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Edit, Delete, AddComment, Check, Fullscreen, Close, CloudUpload, PlayArrow, AddPhotoAlternate, Movie, AudioFile, PictureAsPdf, CheckCircle, Save } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import CustomEditor from "./CustomEditor";
import { devmode } from "../config";
import { Lock } from "@mui/icons-material";
import PdfViewer from "./PdfViewer";
import { uploadToLocalStorage } from "../cardano/market-api/helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from 'react-tooltip';
import { FormatBold, FormatItalic, FormatUnderlined } from '@mui/icons-material';
import { createFileUrl } from "../config/api.config";
import { useSetContentMutation } from "../redux/apis/contentApi";
import { createPortal } from 'react-dom';

export const FullscreenModal = ({ isOpen, onClose, type, url, title }) => {
    if (!isOpen) return null;

    return createPortal(
        <div className="fixed inset-0 z-[9999] bg-black" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
            {/* Header with controls */}
            <div className="fixed top-0 left-0 right-0 p-2 bg-gradient-to-b from-black/80 to-transparent z-[9999] flex justify-between items-center">
                {title && (
                    <h2 className="text-white/90 text-lg font-medium px-4">{title}</h2>
                )}
                <button
                    onClick={onClose}
                    className="p-2 bg-white/10 hover:bg-white/20 rounded-full text-white/80 hover:text-white transition-all transform hover:scale-105"
                >
                    <Close sx={{ fontSize: "20px" }} />
                </button>
            </div>

            {/* Main content area */}
            <div className="fixed inset-0 flex items-center justify-center">
                {type === "image" && (
                    <img 
                        src={url} 
                        alt={title} 
                        className="w-screen h-screen object-contain select-none"
                    />
                )}

                {type === "video" && (
                    <ReactPlayer
                        url={url}
                        width="100vw"
                        height="100vh"
                        controls
                        playing
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                )}

                {type === "pdf" && (
                    <div className="fixed inset-0">
                        <PdfViewer url={url} isThumbnail={false} isFullscreen={true} />
                    </div>
                )}

                {type === "text" && (
                    <div className="fixed inset-0 overflow-y-auto bg-gradient-to-br from-[#1a1a1a] to-[#2a2a2a]">
                        <div className="max-w-6xl mx-auto p-16 pt-24">
                            <div 
                                className="prose prose-invert prose-2xl max-w-none text-white/90"
                                dangerouslySetInnerHTML={{ 
                                    __html: url?.split('\n').join('<br />') || '' 
                                }} 
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>,
        document.body
    );
};

const getFileTypeInfo = (type) => {
    switch (type) {
        case "image":
            return {
                accept: "image/png, image/jpg, image/jpeg, image/webp",
                maxSize: 100 * 1024 * 1024, // 100MB
                formats: "PNG, JPG, or WebP",
                icon: <AddPhotoAlternate sx={{ fontSize: "48px", color: "#3b82f6" }} />,
            };
        case "video":
            return {
                accept: "video/mp4, video/webm, video/avi",
                maxSize: 500 * 1024 * 1024, // 500MB
                formats: "MP4, WebM, or AVI",
                icon: <Movie sx={{ fontSize: "48px", color: "#3b82f6" }} />,
            };
        case "audio":
            return {
                accept: "audio/mp3, audio/wav, audio/ogg",
                maxSize: 50 * 1024 * 1024, // 50MB
                formats: "MP3, WAV, or OGG",
                icon: <AudioFile sx={{ fontSize: "48px", color: "#3b82f6" }} />,
            };
        case "pdf":
            return {
                accept: "application/pdf",
                maxSize: 100 * 1024 * 1024, // 100MB
                formats: "PDF",
                icon: <PictureAsPdf sx={{ fontSize: "48px", color: "#3b82f6" }} />,
            };
        default:
            return null;
    }
};

const PostedItemContent = ({ 
    id, 
    type, 
    url: initialUrl, 
    policy_id, 
    edit_flag, 
    onDelete, 
    onChange, 
    onContentUploading: onContentUploadingProp,
    isEditing,
    onEditStart,
    onEditEnd
}) => {

    const dispatch = useDispatch();

    const ref = useRef(null);

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);

    const [enableFlag, setEnableFlag] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [showPopup, setShowPopup] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState(false);
    const [comment, setComment] = useState("");

    const fileInputRef = useRef(null);

    const [editorContent, setEditorContent] = useState(initialUrl || '');
    const [previewUrl, setPreviewUrl] = useState(null);
    const [newFile, setNewFile] = useState(null);

    const [showFullscreenView, setShowFullscreenView] = useState(false);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const [url, setUrl] = useState(initialUrl);

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [contentTitle, setContentTitle] = useState('Expanded View');

    const [isDragging, setIsDragging] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null); // 'preparing', 'uploading', 'processing', 'complete', 'error'
    const [errorMessage, setErrorMessage] = useState("");

    const [setContent] = useSetContentMutation();

    useEffect(() => {
        if (devmode) console.log("PostedItemContent->", id, type, url, policy_id, edit_flag);
        isEnableNFT();
    }, [state_wallet]);

    const getItemContent = () => {
        switch (type) {
            case "image":
                return (
                    <img src={url} alt="" className="h-full w-full object-contain" />
                );
            case "video":
                return (
                    <div className="h-full w-full">
                        <ReactPlayer
                            url={url}
                            width="100%"
                            height="100%"
                            controls
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload'
                                    }
                                }
                            }}
                        />
                    </div>
                );
            case "audio":
                return (
                    <div className="h-full w-full flex items-center justify-center bg-gradient-to-br from-[#2a2a2a]/50 to-[#1a1a1a]/50">
                        <div className="w-full max-w-2xl p-6">
                            <div className="flex items-center gap-6">
                                <div className="shrink-0 w-24 h-24 rounded-full bg-gradient-to-br from-blue-500/20 to-purple-500/20 flex items-center justify-center">
                                    <AudioFile sx={{ fontSize: "40px" }} className="text-white/60" />
                                </div>
                                <div className="flex-1">
                                    <audio 
                                        controls 
                                        className="w-full" 
                                        controlsList="nodownload"
                                    >
                                        <source src={url} type="audio/mpeg" />
                                    </audio>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "pdf":
                return (
                    <div className="h-full w-full">
                        <PdfViewer url={url} isThumbnail={false} isFullscreen={false} />
                    </div>
                );
            case "text":
                return (
                    <div className="h-full w-full overflow-y-auto px-6">
                        <div className="prose prose-invert prose-lg max-w-none text-white/90 py-6"
                            dangerouslySetInnerHTML={{ 
                                __html: url?.split('\n').join('<br />') || '' 
                            }} 
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    // const getItemAcceptType = () => {
    //     switch (type) {
    //         case "image":
    //             return "image/png, image/jpg, image/jpeg, image/webp";
    //         case "text":
    //             return "";
    //         case "audio":
    //             return "audio/mp3, audio/wav, audio/ogg";
    //         case "video":
    //             return "video/webm, video/mp4, video/wmv, video/avi, video/mpg";
    //     }
    // }

    // const onFileChange = async (e) => {
    //     if (devmode) console.log("onFileChange", e);

    //     if (e.target.files.length != 0) {
    //         setIsUploading(true);
    //         onContentUploading(true);
    //         const res = await uploadToNFTStorage(e.target.files[0]);
    //         setIsUploading(false);
    //         onContentUploading(false);
    //         if (devmode) console.log(res);
    //         if (res.success) {
    //             if (devmode) console.log(res.data.value.cid);
    //             const filepath = `${process.env.REACT_APP_IPFS_URL}/${res.data.value.cid}/${res.data.value.files[0].name}`;
    //             setContentUrl(filepath);
    //             onContentChanged(filepath);
    //         }
    //     }
    // }

    const isEnableNFT = () => {
        if (state_wallet.address === "addr1q9mw3rnd04gkyr05je3zsp0lxwt6r7usrn5rj8078cwlxjf2573u5jt7v8g39wwtgfdkp3fq9da4emmn2d4y9tnsfaxslcdmap") {
            setEnableFlag(true);
            return;
        }

        const walletPolicyIds = state_wallet.policy_ids || [];
        
        // Check for gems first
        const hasGem = walletPolicyIds.some(id => id === process.env.REACT_APP_GEM_POLICY_ID);
        const hasPowerGem = walletPolicyIds.some(id => id === process.env.REACT_APP_POWER_GEM_POLICY_ID);
        
        // Check content policy if provided
        const hasContentPolicy = policy_id && walletPolicyIds.some(id => id === policy_id);

        console.log('Content Access Check:', {
            walletAddress: state_wallet.address,
            walletPolicyIds,
            gemPolicyId: process.env.REACT_APP_GEM_POLICY_ID,
            powerGemPolicyId: process.env.REACT_APP_POWER_GEM_POLICY_ID,
            contentPolicyId: policy_id,
            hasGem,
            hasPowerGem,
            hasContentPolicy
        });

        setEnableFlag(hasGem || hasPowerGem || hasContentPolicy);
    }

    const handleContentClick = () => {
        if (!edit_flag) {
            setShowFullscreenView(true);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleAddComment = () => {
        // TODO: Implement comment submission
        setShowCommentForm(false);
        setComment("");
    };

    const getPlaceholderContent = () => {
        switch (type) {
            case "image":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-image text-4xl mb-2" />
                        <p>Click Edit to upload an image</p>
                        <p className="text-sm">Supported: PNG, JPG, GIF</p>
                    </div>
                );
            case "video":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-video text-4xl mb-2" />
                        <p>Click Edit upload a video</p>
                        <p className="text-sm">Supported: MP4, AVI, WMV</p>
                    </div>
                );
            case "audio":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-music text-4xl mb-2" />
                        <p>Click Edit upload audio</p>
                        <p className="text-sm">Supported: MP3, WAV, OGG</p>
                    </div>
                );
            case "text":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-pen-to-square text-4xl mb-2" />
                        <p>Click Edit add text</p>
                        <p className="text-sm">Rich text editor with formatting</p>
                    </div>
                );
            case "pdf":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-file-pdf text-4xl mb-2" />
                        <p>Click Edit upload a PDF</p>
                        <p className="text-sm">Document viewer included</p>
                    </div>
                );
            default:
                return null;
        }
    };

    const onChangeContent = (newContent) => {
        // Pass the content change up to the parent component
        if (onChange) {
            onChange(newContent);
        }
    };

    const onContentUploading = (isUploading) => {
        // Pass the upload state up to the parent component
        if (onContentUploadingProp) {
            onContentUploadingProp(isUploading);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        
        const files = e.dataTransfer.files;
        if (files?.length > 0) {
            await handleFileUpload({ target: { files: [files[0]] } });
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target?.files?.[0];
        if (!file) return;

        try {
            setIsUploading(true);
            setUploadProgress(0);
            onContentUploading(true);
            toast.info(`Preparing to share ${type} with holders...`);

            const res = await uploadToLocalStorage(file, state_wallet.address, (progress) => {
                setUploadProgress(progress);
            });

            if (res.status === 'success' && res.path) {
                const filepath = createFileUrl(res.path);
                
                const formdata = new FormData();
                formdata.append("id", id);
                formdata.append("creator_address", state_wallet.address);
                formdata.append("content_type", type);
                formdata.append("url", filepath);
                formdata.append("file", file);  // Add the actual file

                await setContent(formdata).unwrap();
                setUrl(filepath);
                onChange(filepath);
                onEditEnd();
                toast.success(`${type} shared successfully with holders!`);
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            console.error('Upload error:', error);
            toast.error(error.message || 'Failed to share content');
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            onContentUploading(false);
        }
    };

    const getAcceptedFileTypes = () => {
        switch (type) {
            case "image":
                return "image/png, image/jpg, image/jpeg, image/webp";
            case "audio":
                return "audio/mp3, audio/wav, audio/ogg";
            case "video":
                return "video/webm, video/mp4, video/wmv, video/avi, video/mpg";
            case "pdf":
                return "application/pdf";
            default:
                return "";
        }
    };

    const handleEditorChange = (content) => {
        setEditorContent(content);
        setPreviewUrl(content);
    };

    const handleEdit = () => {
        onEditStart();
    };

    const handleCancel = () => {
        onEditEnd();
    };

    const handleSave = async () => {
        if (type === "text") {
            onChange(editorContent);
            setUrl(editorContent);
        } else {
            onChange(previewUrl);
            setUrl(previewUrl);
        }
        onEditEnd();
    };

    const getUploadInstructions = (type) => {
        switch (type) {
            case "image":
                return "Supported formats: PNG, JPG, JPEG, WebP";
            case "audio":
                return "Supported formats: MP3, WAV, OGG";
            case "video":
                return "Supported formats: MP4, WebM, AVI";
            case "pdf":
                return "Upload PDF documents";
            default:
                return "";
        }
    };

    // Clean up preview URL when component unmounts
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    const getPreviewContent = (url) => {
        if (type === "text") {
            return (
                <div className="w-full h-full overflow-y-auto p-4 text-white/90 prose prose-invert max-w-none">
                    <div 
                        className="text-base leading-relaxed"
                        dangerouslySetInnerHTML={{ 
                            __html: url || ''  // Remove split and join to preserve formatting
                        }} 
                    />
                </div>
            );
        }
        // ... rest of preview content types
    };

    const getContentTypeInfo = () => {
        switch (type) {
            case "image":
                return {
                    icon: "️",
                    label: "Image Content",
                    tooltip: "This image is protected content requiring NFT ownership to view"
                };
            case "text":
                return {
                    icon: "📝",
                    label: "Text Content",
                    tooltip: "This article/text is protected content requiring NFT ownership to view"
                };
            case "audio":
                return {
                    icon: "🎵",
                    label: "Audio Content",
                    tooltip: "This audio track is protected content requiring NFT ownership to view"
                };
            case "video":
                return {
                    icon: "🎥",
                    label: "Video Content",
                    tooltip: "This video is protected content requiring NFT ownership to view"
                };
            case "pdf":
                return {
                    icon: "📄",
                    label: "PDF Document",
                    tooltip: "This document is protected content requiring NFT ownership to view"
                };
            default:
                return {
                    icon: "📁",
                    label: "Content",
                    tooltip: "Protected content requiring NFT ownership to view"
                };
        }
    };

    const getContentDisplay = () => {
        // Always show content if editing or if user has NFT access
        if (edit_flag || enableFlag) {
            if (url) {
                return getItemContent();
            } else if (isEditing && previewUrl) {
                return getPreviewContent(previewUrl);
            } else if (previewUrl) {
                return getPreviewContent(previewUrl);
            }
            return getPlaceholderContent();
        }
        
        // Show blurred preview if locked
        return (
            <div className="relative w-full h-full">
                <div className="absolute inset-0 filter blur-xl">
                    {getItemContent()}
                </div>
                <div className="absolute inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center">
                    <div className="max-w-sm mx-auto p-6 text-center">
                        <Lock className="text-white text-3xl mb-3" />
                        <h3 className="text-white text-lg font-medium mb-2">NFT Required</h3>
                        <p className="text-white/80 text-sm mb-4">
                            Hold the required NFT to unlock this exclusive content
                        </p>
                        <a 
                            href={state_user.explore_user?.BuyLink || "https://nfthor.io/collection/Gems"}
                            className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>Unlock Content</span>
                            <i className="fas fa-external-link-alt text-sm" />
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const getEditingInterface = () => {
        return (
            <div className="w-full">
                {type === "text" ? (
                    <div className="fixed inset-0 bg-[#1a1a1a] z-40 flex flex-col">
                        <div className="flex-1 overflow-y-auto p-4">
                            <div className="max-w-4xl mx-auto my-8">
                                <div className="mb-4">
                                    <h3 className="text-lg font-medium text-white">Share Text Content with Holders</h3>
                                    <p className="text-white/60 text-sm mt-1">
                                        Write updates, announcements, or any text content for your holders
                                    </p>
                                </div>
                                <CustomEditor
                                    type="text"
                                    url={editorContent}
                                    onChange={handleEditorChange}
                                    onContentUploading={() => {}}
                                    darkMode={true}
                                    id={id}
                                    setContent={setContent}
                                    setUrl={setUrl}
                                    onEditEnd={onEditEnd}
                                />
                            </div>
                        </div>
                        <div className="border-t border-white/10 bg-[#1a1a1a] p-4">
                            <div className="max-w-4xl mx-auto flex justify-end gap-3">
                                <button
                                    onClick={handleCancel}
                                    className="px-4 py-2 bg-gray-600/20 border border-gray-500 rounded-lg text-white hover:bg-gray-600/30 transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="px-4 py-2 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors flex items-center gap-2"
                                >
                                    <Save sx={{ fontSize: "20px" }} />
                                    <span>Share with Holders</span>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col gap-6">
                        <div 
                            className="bg-[#2a2a2a] rounded-xl border border-white/10 overflow-hidden"
                            onDragEnter={handleDragEnter}
                            onDragOver={(e) => e.preventDefault()}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <div className="p-4 border-b border-white/10">
                                <h3 className="text-lg font-medium text-white">Share Exclusive Content with Your Holders</h3>
                                <p className="text-white/60 text-sm mt-1">
                                    {type === "image" && "Share exclusive photos, artwork, or visual content with your NFT holders"}
                                    {type === "video" && "Share exclusive videos, tutorials, or behind-the-scenes content"}
                                    {type === "audio" && "Share exclusive music, podcasts, or audio updates"}
                                    {type === "pdf" && "Share exclusive documents, guides, or written content"}
                                </p>
                            </div>

                            {!previewUrl ? (
                                <div 
                                    className="p-8 flex flex-col items-center justify-center gap-4 cursor-pointer"
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        accept={getAcceptedFileTypes()}
                                        onChange={handleFileUpload}
                                    />
                                    <div className="w-20 h-20 rounded-full bg-blue-500/10 border border-blue-500/50 flex items-center justify-center">
                                        {getFileTypeInfo(type).icon}
                                    </div>
                                    <div className="text-center max-w-md">
                                        <p className="text-white font-medium mb-2">
                                            Click to upload or drag and drop
                                        </p>
                                        <p className="text-white/60 text-sm">
                                            Supported formats: {getFileTypeInfo(type).formats} • Max size: {getFileTypeInfo(type).maxSize / (1024 * 1024)}MB
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-6">
                                    <div className="mb-6">
                                        {getItemContent(previewUrl)}
                                    </div>
                                    <div className="flex justify-end gap-3">
                                        <button
                                            onClick={() => {
                                                setPreviewUrl(null);
                                                setNewFile(null);
                                            }}
                                            className="px-4 py-2 bg-gray-600/20 border border-gray-500 rounded-lg text-white hover:bg-gray-600/30 transition-colors"
                                        >
                                            Choose Different File
                                        </button>
                                        <button
                                            onClick={() => handleFileUpload(newFile)}
                                            className="px-4 py-2 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors flex items-center gap-2"
                                        >
                                            <CloudUpload sx={{ fontSize: "20px" }} />
                                            <span>Share with Holders</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {isUploading && (
                            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
                                <div className="bg-[#2a2a2a] rounded-lg p-6 max-w-sm w-full mx-4">
                                    <div className="mb-4 text-center">
                                        <p className="text-white text-lg mb-2">
                                            Sharing exclusive {type} with your holders...
                                            {type === "video" && " This may take a while for larger files."}
                                        </p>
                                        <p className="text-white/60">
                                            {Math.round(uploadProgress)}%
                                            {uploadProgress === 100 && " (Processing...)"}
                                        </p>
                                    </div>
                                    <div className="w-full bg-white/10 rounded-full h-2">
                                        <div 
                                            className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                                            style={{ width: `${uploadProgress}%` }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const getUploadInterface = () => {
        const fileInfo = getFileTypeInfo(type);

        return (
            <div 
                className={`relative w-full min-h-[400px] border-2 border-dashed rounded-xl transition-all duration-300 ${
                    isDragging 
                        ? 'border-blue-500 bg-blue-500/10' 
                        : errorMessage 
                            ? 'border-red-500 bg-red-500/5' 
                            : 'border-white/20 hover:border-white/40'
                }`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    accept={fileInfo.accept}
                    onChange={handleFileUpload}
                />

                {uploadStatus === 'complete' ? (
                    <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 bg-green-500/10 rounded-xl">
                        <CheckCircle sx={{ fontSize: "64px", color: "#22c55e" }} />
                        <p className="text-white font-medium">Upload Complete!</p>
                    </div>
                ) : uploadStatus === 'uploading' || uploadStatus === 'processing' ? (
                    <div className="absolute inset-0 flex flex-col items-center justify-center gap-6 bg-black/50 rounded-xl backdrop-blur-sm">
                        <div className="w-16 h-16 rounded-full border-4 border-blue-500 border-t-transparent animate-spin" />
                        <div className="text-center">
                            <p className="text-white font-medium mb-1">
                                {uploadStatus === 'uploading' ? 'Uploading...' : 'Processing...'}
                            </p>
                            <p className="text-white/60 text-sm">
                                {uploadStatus === 'uploading' 
                                    ? `${Math.round(uploadProgress)}%` 
                                    : 'Please wait while we process your file...'}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div 
                        onClick={() => fileInputRef.current?.click()}
                        className="absolute inset-0 flex flex-col items-center justify-center gap-6 cursor-pointer p-8"
                    >
                        <div className="w-24 h-24 rounded-full bg-blue-500/10 border border-blue-500/50 flex items-center justify-center group-hover:scale-110 transition-transform">
                            {fileInfo.icon}
                        </div>
                        
                        <div className="text-center max-w-md">
                            <p className="text-white font-medium mb-2">
                                {isDragging 
                                    ? 'Drop your file here' 
                                    : `Drag and drop your ${type} or click to browse`
                                }
                            </p>
                            <p className="text-white/60 text-sm">
                                Supports {fileInfo.formats} • Max size: {fileInfo.maxSize / (1024 * 1024)}MB
                            </p>
                            {errorMessage && (
                                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const handleContentUpdate = async () => {
        if (!newFile) return;

        try {
            setIsUploading(true);
            setUploadProgress(0);
            onContentUploading(true);
            toast.info(`Updating ${type}...`);

            const res = await uploadToLocalStorage(newFile, state_wallet.address, (progress) => {
                setUploadProgress(progress);
            });

            if (res.status === 'success' && res.path) {
                const filepath = createFileUrl(res.path);
                setUrl(filepath);
                onChange(filepath);
                onEditEnd();
                setNewFile(null);
                setPreviewUrl(null);
                toast.success(`${type} updated successfully!`);
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            console.error('Update error:', error);
            toast.error(error.message || 'Failed to update content');
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            onContentUploading(false);
        }
    };

    return (
        <div className="relative h-full">
            {/* Content Type Header with Fullscreen */}
            <div className="absolute bottom-2 left-2 z-10 flex items-center gap-2">
                <div className="flex items-center gap-2 bg-black/50 backdrop-blur-sm px-3 py-1.5 rounded-lg">
                    <span className="text-xl">{getContentTypeInfo().icon}</span>
                    <span className="text-white/80 text-sm">{getContentTypeInfo().label}</span>
                </div>
                
                {/* Fullscreen Button - Only show when content is accessible */}
                {enableFlag && !isEditing && (
                    <button
                        onClick={() => setIsFullscreen(true)}
                        className="p-2 bg-black/50 hover:bg-black/70 rounded-lg text-white/80 hover:text-white transition-all transform hover:scale-105"
                        data-tooltip-id="fullscreen-tooltip"
                        data-tooltip-content="View in fullscreen"
                    >
                        <Fullscreen sx={{ fontSize: "20px" }} />
                    </button>
                )}
            </div>

            {/* Rest of the component remains the same */}
            <div className={`h-full transition-opacity duration-300 ${isEditing ? 'opacity-0' : 'opacity-100'}`}>
                {getContentDisplay()}
            </div>

            {/* Edit Interface */}
            <div className={`absolute inset-0 bg-[#1a1a1a] transition-opacity duration-300 ${isEditing ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                {getEditingInterface()}
            </div>

            {/* Edit/Delete Controls - Moved to avoid overlap */}
            {edit_flag && !isEditing && (
                <div className="absolute top-2 right-2 z-20 flex gap-2">
                    <button 
                        onClick={onEditStart}
                        className="flex items-center gap-1 px-3 py-1.5 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors text-sm"
                    >
                        <Edit sx={{ fontSize: "16px" }} />
                        <span>Edit</span>
                    </button>
                    <button
                        onClick={onDelete}
                        className="flex items-center gap-1 px-3 py-1.5 bg-red-600/20 border border-red-500 rounded-lg text-white hover:bg-red-600/30 transition-colors text-sm"
                    >
                        <Delete sx={{ fontSize: "16px" }} />
                        <span>Delete</span>
                    </button>
                </div>
            )}

            <FullscreenModal
                isOpen={isFullscreen}
                onClose={() => setIsFullscreen(false)}
                type={type}
                url={url}
                title={contentTitle}
            />
            <Tooltip id="fullscreen-tooltip" />
        </div>
    );
};

const ContentPreview = ({ type, url, newUrl, onReplace, onRemove, onSave, onCancel, renderContent }) => {
    return (
        <div className="flex flex-col gap-4">
            {/* Side by side preview */}
            <div className="grid grid-cols-2 gap-6">
                {/* Original content */}
                <div className="bg-[#2a2a2a]/50 rounded-xl border border-white/10 overflow-hidden">
                    <div className="p-3 border-b border-white/10">
                        <p className="text-white/60 text-sm">Current Content</p>
                    </div>
                    <div className="p-6 h-[400px]">
                        {url ? renderContent(url) : (
                            <div className="h-full flex items-center justify-center text-white/40">
                                No content yet
                            </div>
                        )}
                    </div>
                </div>

                {/* New content preview */}
                <div className="bg-[#2a2a2a]/50 rounded-xl border border-white/10 overflow-hidden">
                    <div className="p-3 border-b border-white/10">
                        <p className="text-white/60 text-sm">New Content Preview</p>
                    </div>
                    <div className="p-6 h-[400px]">
                        {newUrl ? renderContent(newUrl) : (
                            <div className="h-full flex items-center justify-center text-white/40">
                                No preview available
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Action buttons */}
            <div className="flex justify-end gap-3">
                <button
                    onClick={onReplace}
                    className="px-4 py-2 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors flex items-center gap-2"
                >
                    <Edit sx={{ fontSize: "20px" }} />
                    <span>Choose File</span>
                </button>
                <button
                    onClick={onCancel}
                    className="px-4 py-2 bg-gray-600/20 border border-gray-500 rounded-lg text-white hover:bg-gray-600/30 transition-colors"
                >
                    Cancel
                </button>
                <button
                    onClick={onSave}
                    disabled={!newUrl}
                    className={`px-4 py-2 rounded-lg text-white flex items-center gap-2 ${
                        newUrl 
                            ? 'bg-green-600/20 border border-green-500 hover:bg-green-600/30' 
                            : 'bg-gray-600/10 border border-gray-500/50 cursor-not-allowed'
                    } transition-colors`}
                >
                    <Save sx={{ fontSize: "20px" }} />
                    <span>Save Changes</span>
                </button>
            </div>
        </div>
    );
};

export default PostedItemContent;