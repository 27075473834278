import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PostedItemContent, { FullscreenModal } from "./PostedItemContent";
import ShowItem from "./ShowItem";

import { Edit, Delete, Check, AddComment, Comment, Fullscreen } from "@mui/icons-material";
import { setEditingId } from "../redux/features/walletSlice";
import { useSetContentMutation, useDeleteContentMutation } from "../redux/apis/contentApi";
import { useSetCommentMutation } from "../redux/apis/commentApi";

import { devmode } from "../config";

import CustomEditor from "./CustomEditor";
import draftToHtml from 'draftjs-to-html';
import { Tooltip } from '@mui/material';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const PostedItem = ({ id, title, description, policy_id, type, url: initialUrl, edit_flag, showFullscreen = false, isCreatePage = false }) => {

    const dispatch = useDispatch();
    const ref = useRef(null);

    const state_wallet = useSelector((state) => state.walletState);
    const state_comment = useSelector((state) => state.commentState);
    const state_user = useSelector((state) => state.userState);

    const [setContent] = useSetContentMutation();
    const [deleteContent] = useDeleteContentMutation();

    const [setComment] = useSetCommentMutation();

    const [eTitle, setETitle] = useState(title);
    const [eDescription, setEDescription] = useState(description);
    const [eUrl, setEUrl] = useState(initialUrl);
    const [eComment, setEComment] = useState("");

    const [showAskDelete, setShowAskDelete] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);
    const [showComment, setShowComment] = useState(false);

    const [enableFlag, setEnableFlag] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [commentShowCount, setCommentShowCount] = useState(0);

    const [showContentEditor, setShowContentEditor] = useState(false);
    const [showTitleEditor, setShowTitleEditor] = useState(false);
    const [showDescriptionEditor, setShowDescriptionEditor] = useState(false);

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);

    const [parsedDescription, setParsedDescription] = useState('');

    const [isFullscreen, setIsFullscreen] = useState(false);

    const [isEditing, setIsEditing] = useState(false);

    const [url, setUrl] = useState(initialUrl);

    useEffect(() => {
        if (devmode) console.log("PostedItem->", id, title, description, policy_id, type, initialUrl, edit_flag);
        isEnableNFT();
    }, [state_wallet]);

    useEffect(() => {
        if (!isEditingTitle && !isEditingDescription) {
            setETitle(title || '');
            setEDescription(description || '');
        }
    }, [title, description, isEditingTitle, isEditingDescription]);

    const onEdit = () => {
        if (devmode) console.log("onEdit", id);
        if (state_wallet.editingId != 0) {
            return;
        }
        dispatch(setEditingId(id));
    }

    const onDelete = () => {
        if (devmode) console.log("onDelete", id);
        if (state_wallet.editingId != 0) {
            return;
        }
        setShowAskDelete(true);
    }

    const onEditFihished = () => {
        if (devmode) console.log("onEditFihished", id);
        dispatch(setEditingId(0));

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const closeAskDelete = (e) => {
        setShowAskDelete(false);
    }

    const deleteCurrentContent = () => {
        const formdata = new FormData();
        formdata.append("id", id);
        deleteContent(formdata);
    }

    const closePlayer = (e) => {
        setShowPlayer(false);
    }

    const addComment = (e) => {
        setShowPlayer(false);
        setShowComment(true);
    }

    const closeComment = (e) => {
        setShowComment(false);
    }

    const okComment = () => {
        setShowComment(false);

        const formdata = new FormData();
        formdata.append("content_id", id);
        formdata.append("description", eComment);
        formdata.append("created_by", state_wallet.address);

        setComment(formdata);
    }

    const onChangeComment = (new_comment) => {
        setEComment(new_comment);
    }

    const onChangeContent = async (newUrl) => {
        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", title);
        formdata.append("description", description);
        formdata.append("content_type", type);
        formdata.append("url", newUrl);

        try {
            await setContent(formdata).unwrap();
            setEUrl(newUrl); // Update edit state
            setUrl(newUrl);  // Update display state
        } catch (error) {
            console.error('Failed to save content:', error);
            toast.error('Failed to save changes');
        }
    };

    const closeContentEditor = (e) => {
        setShowContentEditor(false);
    }

    const okContent = () => {
        setShowContentEditor(false);

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const onChangeTitle = (new_title) => {
        setETitle(new_title);
    }

    const closeTitleEditor = (e) => {
        setShowTitleEditor(false);
    }

    const okTitle = () => {
        setShowTitleEditor(false);

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const onChangeDescription = (new_description) => {
        setEDescription(new_description);
    }

    const closeDescriptionEditor = (e) => {
        setShowDescriptionEditor(false);
    }

    const okDescription = () => {
        setShowDescriptionEditor(false);

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const isEnableNFT = () => {
        // Dev wallet check
        if (state_wallet.address === "addr1qx5fx5wg0scahca67s3eh8c5suyqaa0jppecrztqd80ru6f7fr59rddrj47q0c206dcgzqhcakr5t3crw04y5n8522zq8caymk") {
            setEnableFlag(true);
            return;
        }

        const walletPolicyIds = state_wallet.policy_ids || [];
        const userContentPolicy = state_user?.user?.PolicyId;
        
        // Use Set for O(1) lookups
        const policySet = new Set(walletPolicyIds);

        const hasGem = policySet.has(process.env.REACT_APP_GEM_POLICY_ID);
        const hasPowerGem = policySet.has(process.env.REACT_APP_POWER_GEM_POLICY_ID);
        const hasContentPolicy = policy_id && policySet.has(policy_id);
        const hasUserContentPolicy = userContentPolicy && policySet.has(userContentPolicy);

        setEnableFlag(hasGem || hasPowerGem || hasContentPolicy || hasUserContentPolicy);
    }

    const onContentUploading = (isContentUploading) => {
        setIsUploading(isContentUploading);
    }

    const onReadMore = () => {
        let count = commentShowCount + 5;
        setCommentShowCount(count);
    }

    const onLess = () => {
        let count = commentShowCount - 5;
        setCommentShowCount(count);
    }

    const openContentEditor = () => {
        setShowContentEditor(true);
    }

    // Individual section edit handlers
    const handleTitleEdit = () => {
        setShowTitleEditor(true);
    };

    const handleContentEdit = () => {
        setShowContentEditor(true);
    };

    const handleDescriptionEdit = () => {
        setShowDescriptionEditor(true);
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this content?')) {
            deleteCurrentContent();
        }
    };

    const handleSaveField = async (field) => {
        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        
        // Special handling for mobile
        const processContent = (content) => {
            if (!content) return '';
            // For mobile browsers, handle content differently
            if (isMobile) {
                return content
                    .replace(/(<div>|<\/div>|<p>|<\/p>)/g, '')
                    .replace(/<br\s*\/?>/g, '\n')
                    .replace(/&nbsp;/g, ' ')
                    .trim();
            }
            // Desktop processing remains the same
            return content
                .replace(/<div>/g, '\n')
                .replace(/<\/div>/g, '')
                .replace(/<br\s*\/?>/g, '\n')
                .replace(/&nbsp;/g, ' ')
                .trim();
        };

        let titleContent = title;
        let descriptionContent = description;

        if (field === 'title') {
            titleContent = processContent(eTitle);
        }
        if (field === 'description') {
            descriptionContent = processContent(eDescription);
        }

        formdata.append("title", titleContent);
        formdata.append("description", descriptionContent);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);

        try {
            const result = await setContent(formdata).unwrap();
            
            if (field === 'title') {
                setIsEditingTitle(false);
                setETitle(titleContent);
            }
            if (field === 'description') {
                setIsEditingDescription(false);
                setEDescription(descriptionContent);
            }

            // Force a re-render of the content
            if (isMobile) {
                setTimeout(() => {
                    setETitle(titleContent);
                    setEDescription(descriptionContent);
                }, 100);
            }

            toast.success(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully`);
        } catch (error) {
            console.error('Failed to save:', error);
            toast.error('Failed to save changes');
        }
    };

    const renderFullscreenButton = () => {
        // Only show fullscreen button if content is accessible (editing mode or user has NFT access)
        if (!edit_flag && !enableFlag) return null;

        return (
            <button
                onClick={() => setIsFullscreen(true)}
                className="absolute top-2 right-2 z-10 p-2 bg-black/50 hover:bg-black/70 rounded-full text-white/80 hover:text-white transition-all transform hover:scale-105"
                data-tooltip-id="fullscreen-tooltip"
                data-tooltip-content="View in fullscreen"
            >
                <Fullscreen sx={{ fontSize: "20px" }} />
            </button>
        );
    };

    return (
        <div className="relative w-full flex flex-col gap-4 sm:gap-8 bg-gradient-to-br from-[#0f172a]/95 via-[#1e293b]/95 to-[#0f172a]/95 backdrop-blur-xl p-4 sm:p-8 rounded-2xl border border-indigo-500/10 shadow-2xl shadow-indigo-500/5 group transition-all duration-500">
            {/* Animated gradient border effect */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-blue-500/10 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-500" />
            
            {/* Hover glow effect */}
            <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-blue-500/20 opacity-0 group-hover:opacity-100 blur transition-opacity duration-500" />
            
            {/* Content wrapper */}
            <div className="relative z-10 flex flex-col gap-4 sm:gap-8">
                {/* Title Section */}
                <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-[#1e293b]/90 to-[#0f172a]/90 border border-indigo-500/20 shadow-lg shadow-indigo-500/5 backdrop-blur-xl group/title">
                    <div className="relative p-4 sm:p-7">
                        {isEditingTitle ? (
                            <div className="space-y-4">
                                <CustomEditor
                                    type="text"
                                    url={eTitle}
                                    onChange={(value) => setETitle(value)}
                                    onContentUploading={() => {}}
                                    darkMode={true}
                                    placeholder="Add a title that will help holders understand what this content is about..."
                                    singleLine={true}
                                    mobileOptimized={true}
                                    isMobileDevice={isMobile}
                                />
                                <div className="flex justify-end gap-2 pt-2">
                                    <button
                                        onClick={() => setIsEditingTitle(false)}
                                        className="px-3 sm:px-4 py-1.5 sm:py-2 text-white/60 hover:text-white transition-colors text-sm sm:text-base"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => handleSaveField('title')}
                                        className="flex items-center gap-1.5 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-gradient-to-r from-green-500/20 via-emerald-500/20 to-green-500/20 hover:from-green-500/30 hover:via-emerald-500/30 hover:to-green-500/30 border border-green-500/50 hover:border-green-500 rounded-lg text-white transition-all duration-300 text-sm sm:text-base"
                                    >
                                        <Check sx={{ fontSize: "18px" }} />
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-3 sm:gap-6">
                                <p className="w-full overflow-hidden text-lg sm:text-2xl text-transparent bg-gradient-to-r from-white via-white/90 to-white/80 bg-clip-text font-semibold leading-relaxed tracking-wide" 
                                   dangerouslySetInnerHTML={{ __html: title }} 
                                />
                                {edit_flag && (
                                    <button 
                                        onClick={() => setIsEditingTitle(true)}
                                        className="shrink-0 flex items-center gap-1.5 sm:gap-2 px-3 sm:px-3.5 py-1.5 sm:py-2 bg-gradient-to-r from-blue-600/20 via-purple-600/20 to-blue-600/20 hover:from-blue-600/30 hover:via-purple-600/30 hover:to-blue-600/30 border border-blue-500/50 hover:border-blue-500 rounded-lg text-white transition-all duration-300 text-sm group/edit"
                                    >
                                        <Edit sx={{ fontSize: "18px" }} />
                                        <span>Edit</span>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* Content Section */}
                <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-[#1e293b]/90 to-[#0f172a]/90 border border-indigo-500/20 shadow-lg shadow-indigo-500/5 backdrop-blur-xl h-[400px] group/content">
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/5 via-purple-500/5 to-blue-500/5 opacity-0 group-hover/content:opacity-100 transition-opacity duration-300" />
                    <PostedItemContent 
                        id={id}
                        type={type}
                        url={eUrl}
                        policy_id={policy_id}
                        edit_flag={edit_flag}
                        onDelete={handleDelete}
                        onChange={onChangeContent}
                        onContentUploading={setIsUploading}
                        isEditing={isEditing}
                        onEditStart={() => setIsEditing(true)}
                        onEditEnd={() => setIsEditing(false)}
                        showFullscreenOption={showFullscreen}
                    />
                </div>

                {/* Description Section */}
                <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-[#1e293b]/90 to-[#0f172a]/90 border border-indigo-500/20 shadow-lg shadow-indigo-500/5 backdrop-blur-xl group/desc">
                    <div className="relative p-4 sm:p-7">
                        {isEditingDescription ? (
                            <div className="space-y-4">
                                <CustomEditor
                                    type="text"
                                    url={eDescription}
                                    onChange={(value) => setEDescription(value)}
                                    onContentUploading={() => {}}
                                    darkMode={true}
                                    placeholder="Add a description to give more details about your content..."
                                    mobileOptimized={true}
                                    multiline={true}
                                    isMobileDevice={isMobile}
                                />
                                <div className="flex justify-end gap-2 pt-2">
                                    <button
                                        onClick={() => setIsEditingDescription(false)}
                                        className="px-3 sm:px-4 py-1.5 sm:py-2 text-white/60 hover:text-white transition-colors text-sm sm:text-base"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => handleSaveField('description')}
                                        className="flex items-center gap-1.5 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-gradient-to-r from-green-500/20 via-emerald-500/20 to-green-500/20 hover:from-green-500/30 hover:via-emerald-500/30 hover:to-green-500/30 border border-green-500/50 hover:border-green-500 rounded-lg text-white transition-all duration-300 text-sm sm:text-base"
                                    >
                                        <Check sx={{ fontSize: "18px" }} />
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-3 sm:gap-6">
                                <div 
                                    className="w-full overflow-hidden prose prose-invert prose-base sm:prose-lg max-w-none text-white/90 hover:text-white transition-colors duration-300 leading-relaxed prose-p:my-1.5 sm:prose-p:my-2 first:prose-p:mt-0 last:prose-p:mb-0"
                                    dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, '<br/>') }} 
                                />
                                {edit_flag && (
                                    <button 
                                        onClick={() => setIsEditingDescription(true)}
                                        className="shrink-0 flex items-center gap-1.5 sm:gap-2 px-3 sm:px-3.5 py-1.5 sm:py-2 bg-gradient-to-r from-blue-600/20 via-purple-600/20 to-blue-600/20 hover:from-blue-600/30 hover:via-purple-600/30 hover:to-blue-600/30 border border-blue-500/50 hover:border-blue-500 rounded-lg text-white transition-all duration-300 text-sm group/edit"
                                    >
                                        <Edit sx={{ fontSize: "16px" }} className="transform group-hover/edit:rotate-12 transition-transform duration-300" />
                                        <span>Edit</span>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <FullscreenModal
                isOpen={isFullscreen}
                onClose={() => setIsFullscreen(false)}
                type={type}
                url={eUrl}
                title={title}
            />
            <Tooltip id="fullscreen-tooltip" />
        </div>
    );
};

export default PostedItem;