import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostedItem from "../components/PostedItem";
import { useGetContentsByCodeQuery, useSetContentMutation } from "../redux/apis/contentApi";
import { useGetUserByCodeQuery } from "../redux/apis/userApi";
import { useGetCommentsByCodeQuery } from "../redux/apis/commentApi";
import { ShareSocial } from 'react-share-social';
import { devmode } from "../config";

const Explore = () => {
    const { code } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);
    const state_content = useSelector((state) => state.contentState);

    // Important API queries
    useGetContentsByCodeQuery(code);
    useGetUserByCodeQuery(code);
    useGetCommentsByCodeQuery(code);

    useEffect(() => {
        if (devmode) console.log("state_wallet, code", state_wallet, code)
    }, [state_wallet.connected])

    return (
        <div className="w-full h-auto flex flex-col gap-[10px] lg:gap-[10px] bg-main_bg">
            <div className="relative w-full min-h-[100vh] flex flex-col gap-[20px] items-center px-[10px] z-0 pb-[100px] lg:pb-[60px]">
                {/* Banner and Profile Section First */}
                <div className="relative w-full mb-16">
                    <div className="w-full h-[300px] md:h-[400px] rounded-2xl overflow-hidden group">
                        <img 
                            src={state_user.explore_user?.Banner || "/images/stk_banner_bg.png"} 
                            alt="Banner" 
                            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105 blur-up lazyload"
                            loading="lazy"
                        />
                    </div>
                    
                    {/* Profile Badge */}
                    <div className="absolute -bottom-16 left-1/2 -translate-x-1/2 z-20">
                        <div className="relative">
                            <img 
                                src={state_user.explore_user?.Badge || "/images/stk_icon.png"} 
                                alt="Profile" 
                                className="w-[120px] h-[120px] rounded-full border-4 border-white/10 object-cover" 
                            />
                        </div>
                    </div>
                </div>

                {/* Welcome Section */}
                <div className="text-center">
                    <h1 className="text-[40px] md:text-[64px] font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400 animate-gradient leading-tight mb-6">
                        Welcome to <span dangerouslySetInnerHTML={{ 
                            __html: state_user.explore_user 
                                ? state_user.explore_user.Title.replace("<p>", "<span>").replace("</p>", "</span>") 
                                : "" 
                        }} /> StreamKey!
                    </h1>

                    {/* NFT Requirement Message */}
                    {state_user.explore_user && (
                        <div className="relative max-w-2xl mx-auto">
                            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl blur-lg"></div>
                            <p className="relative text-[18px] md:text-[22px] font-medium text-white/90 tracking-wide leading-relaxed px-6 py-4">
                                You must have a <a 
                                    className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 hover:from-blue-300 hover:to-purple-300 transition-all duration-300" 
                                    href={state_user.explore_user.BuyLink || "https://nfthor.io/collection/Gems"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {state_user.explore_user.NftName || "Gems"}
                                </a> NFT in your wallet to access content.
                            </p>
                        </div>
                    )}
                </div>

                {/* Social Share Section */}
                <div className="flex flex-col items-center gap-2 mt-4">
                    <p className="text-white/80 text-sm font-medium">
                        Share this StreamKey page with others
                    </p>
                    <ShareSocial
                        url={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user ? state_user.user.Code : ""}`}
                        socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
                        style={{
                            root: {
                                background: 'transparent',
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                                gap: "1rem",
                            },
                            copyContainer: {
                                display: 'none',
                            },
                            socialButton: {
                                padding: "0",
                                margin: "0",
                                transform: 'scale(0.9)',
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    transform: 'scale(1)',
                                }
                            }
                        }}
                    />
                </div>

                {/* Content Section */}
                <div className="w-5/6 md:w-1/2 flex flex-col gap-[30px] !-mt-[40px]">
                    {/* Title */}
                    {state_user.explore_user?.Title && (
                        <div className="relative flex flex-row justify-center">
                            <p className="w-full text-[36px] md:text-[48px] font-bold text-center text-white" 
                               dangerouslySetInnerHTML={{ __html: state_user.explore_user.Title }} />
                        </div>
                    )}

                    {/* Creator Name */}
                    {state_user.explore_user?.CreatorName && (
                        <div className="relative flex flex-row justify-center">
                            <p className="w-full text-[24px] md:text-[32px] font-semibold text-center text-white" 
                               dangerouslySetInnerHTML={{ __html: state_user.explore_user.CreatorName }} />
                        </div>
                    )}

                    {/* Description */}
                    <div className="relative flex flex-row">
                        <p className="w-full text-[16px] md:text-[18px] text-white bg-white/10 backdrop-blur-sm rounded-lg px-[10px] py-[5px]" 
                           dangerouslySetInnerHTML={{ __html: state_user.explore_user ? state_user.explore_user.Description : "" }} />
                    </div>

                    {/* Posted Contents */}
                    <div className="flex flex-col gap-[20px]">
                        {state_content.explore_contents.length !== 0 && (
                            <div className="text-[24px] md:text-[32px] font-bold text-white text-center">
                                Posted Contents
                            </div>
                        )}
                        
                        {state_content.explore_contents.map((content) => (
                            <PostedItem 
                                key={content.ID}
                                id={content.ID} 
                                title={content.Title} 
                                description={content.Description} 
                                policy_id={state_user.explore_user ? state_user.explore_user.PolicyId : ""} 
                                type={content.ContentType} 
                                url={content.Url} 
                                edit_flag={false} 
                            />
                        ))}

                        {state_content.explore_contents.length === 0 && (
                            <div className="text-[20px] lg:text-[30px] font-bold text-white text-center">
                                You do not have any assets yet. Please go to Create page and create an asset.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Explore;