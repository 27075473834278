import { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const FAQ = () => {
    const navigate = useNavigate();
    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);

    const [openIndex, setOpenIndex] = useState(null);

    const handleCreateClick = () => {
        if (!state_wallet.connected) {
            toast.error("Connect Wallet First!");
            return;
        }
        navigate("/create");
    };

    const faqData = [
        {
            question: "What is StreamKey?",
            answer: (
                <p className="text-white/70">
                    StreamKey is a decentralized platform that allows creators to share exclusive content with their NFT holders on the Cardano blockchain. Ready to start? <button 
                        onClick={handleCreateClick}
                        className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 hover:from-blue-300 hover:to-purple-300 transition-all duration-300 font-semibold"
                    >
                        Create your StreamKey
                    </button> now!
                </p>
            )
        },
        {
            question: "How do I get started with StreamKey?",
            answer: (
                <p className="text-white/70">
                    Connect your Cardano wallet, then <button 
                        onClick={handleCreateClick}
                        className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 hover:from-blue-300 hover:to-purple-300 transition-all duration-300 font-semibold"
                    >
                        create your StreamKey profile
                    </button>. If you already have a StreamKey, you can {state_wallet.connected && state_user.user ? (
                        <a 
                            href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user.Code}`}
                            className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 hover:from-blue-300 hover:to-purple-300 transition-all duration-300 font-semibold"
                        >
                            access your StreamKey here
                        </a>
                    ) : (
                        <button 
                            onClick={() => toast.error("Connect Wallet First!")}
                            className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 hover:from-blue-300 hover:to-purple-300 transition-all duration-300 font-semibold"
                        >
                            access your StreamKey
                        </button>
                    )}.
                </p>
            )
        },
        {
            question: "Which wallets are supported?",
            answer: "StreamKey supports major Cardano wallets including Nami, Eternl, Flint, Gero, Typhon, and Begin."
        },
        {
            question: "How do Gems and PowerGems work on StreamKey?",
            answer: (
                <p className="text-white/70">
                    Both <a 
                        href="https://nfthor.io/collection/Gems"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 hover:from-blue-300 hover:to-purple-300 transition-all duration-300 font-semibold"
                    >
                        Gems and PowerGems NFTs
                    </a> in your connected wallet grant universal access to all Gems-gated content on StreamKey. Either NFT type serves as a universal pass for exclusive content across the platform.
                </p>
            )
        },
        {
            question: "Who runs StreamKey?",
            answer: "StreamKey is a community-driven project developed and maintained by the ADAYield.io community. We believe in decentralized content creation and NFT utility on Cardano."
        },
        {
            question: "What type of content can I share?",
            answer: "You can share various types of content including text, images, audio, video, and PDF files with your NFT holders."
        },
        {
            question: "How do NFTs control access to content?",
            answer: "Content creators can specify which NFTs (by policy ID) are required to access their content. Only wallet addresses holding the specified NFTs can view the protected content."
        },
        {
            question: "How do I access creator content?",
            answer: "Simply hold the required NFT in your connected wallet and you'll automatically gain access to the creator's exclusive content. Each creator specifies which NFTs grant access to their content."
        },
        {
            question: "How do I monetize my content?",
            answer: "Create and sell NFTs that grant access to your exclusive content. Your NFT holders automatically get access to your StreamKey content."
        },
        {
            question: "Can I customize my StreamKey page?",
            answer: "Yes, you can customize your profile with a banner image, profile picture, and organize your content however you prefer."
        },
        {
            question: "Is there a fee to use StreamKey?",
            answer: "StreamKey is free to use. You only pay standard Cardano network fees when interacting with the blockchain."
        },
        {
            question: "How secure is StreamKey?",
            answer: "StreamKey leverages Cardano blockchain security and NFT verification to ensure only authorized users can access protected content."
        }
    ];

    return (
        <div className="space-y-4">
            {faqData.map((faq, index) => (
                <div 
                    key={index}
                    className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden transition-all duration-300 hover:border-white/20"
                >
                    <button
                        onClick={() => setOpenIndex(openIndex === index ? null : index)}
                        className="w-full px-6 py-4 text-left flex justify-between items-center"
                    >
                        <span className="text-white font-medium text-lg">{faq.question}</span>
                        <KeyboardArrowDown 
                            className={`text-white/60 transition-transform duration-300 ${
                                openIndex === index ? 'rotate-180' : ''
                            }`}
                        />
                    </button>
                    <div
                        className={`overflow-hidden transition-all duration-300 ${
                            openIndex === index ? 'max-h-96 px-6 pb-4' : 'max-h-0'
                        }`}
                    >
                        <p className="text-white/70">{faq.answer}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQ; 