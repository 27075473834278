import { useEffect } from "react";
import { useNavigate } from "react-router";

import { devmode } from "../config";

const HomePostItem = ({ asset }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (devmode) console.log("HomePostItem->", asset);
    }, []);

    // Function to strip HTML tags and decode HTML entities
    const stripHtmlTags = (str) => {
        if (!str) return "";
        // Remove HTML tags
        let text = str.replace(/<[^>]*>/g, '');
        // Decode HTML entities
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    return (
        <a href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${asset.Code}`} className="flex flex-row justify-center">
            <div className="w-full max-w-sm flex flex-col border border-gray-500 hover:cursor-pointer">
                <div className="relative w-full h-[200px] md:h-[250px] rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105">
                    <img 
                        src={asset.Banner ? asset.Banner : "/images/stk_banner_bg.png"} 
                        alt={asset.Title ? asset.Title : "Banner"} 
                        className="w-full h-full object-cover blur-up lazyload"
                        loading="lazy"
                    />
                    <img 
                        src={asset.Badge ? asset.Badge : "/images/default_badge.png"} 
                        alt="StreamKey Badge" 
                        className="absolute top-2 left-1/2 transform -translate-x-1/2 w-12 h-12 rounded-full blur-up lazyload"
                        loading="lazy"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-4 text-white">
                        <div className="font-bold text-lg">{asset.Title ? asset.Title : "No Title"}</div>
                        <div className="text-sm">{stripHtmlTags(asset.Description).substring(0, 100) + '...'}</div>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default HomePostItem;