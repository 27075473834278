import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react'
import { uploadToLocalStorage } from "../cardano/market-api/helper"
import { createFileUrl } from "../config/api.config"
import { Fullscreen, Close } from '@mui/icons-material'
import { createPortal } from 'react-dom'
import { toast } from "react-toastify"

const CustomEditor = ({
  type,
  url,
  onChange,
  onContentUploading,
  darkMode = true,
  placeholder,
  singleLine,
  mobileOptimized,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const editorRef = useRef(null)
  const walletAddress = useSelector((state) => state.walletState.address)

  const handleFileUpload = async (blobInfo) => {
    try {
      if (!walletAddress) {
        throw new Error('Please connect your wallet first')
      }

      onContentUploading(true)
      toast.info(`Preparing to share ${type} with holders...`)

      const file = blobInfo.blob()
      const res = await uploadToLocalStorage(file, walletAddress)

      if (res.status === 'success' && res.path) {
        const filepath = createFileUrl(res.path)
        toast.success(`${type} shared successfully with holders!`)
        return filepath
      } else {
        throw new Error('Upload failed')
      }
    } catch (error) {
      console.error('Upload error:', error)
      toast.error(error.message || 'Failed to share content')
      throw error
    } finally {
      onContentUploading(false)
    }
  }

  const getEditorConfig = (isFullscreenMode = false) => ({
    // Disable all analytics and tracking
    tracking: false,
    send_usage_statistics: false,
    automatic_uploads: false,
    collect_metrics: false,
    referrer_policy: 'no-referrer',
    
    // Prevent external requests
    external_plugins: {},
    remove_script_host: true,
    convert_urls: true,
    relative_urls: true,
    
    // Block all analytics endpoints
    blocked_domains: [
      'sp.tinymce.com',
      'cdn.tiny.cloud',
      'tracking.tiny.cloud',
      'analytics.tinymce.com'
    ],

    height: isFullscreenMode ? '90vh' : (mobileOptimized ? 300 : 500),
    menubar: !mobileOptimized,
    plugins: [
      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
      'searchreplace', 'visualblocks', 'code', 'fullscreen',
      'insertdatetime', 'media', 'table', 'help', 'wordcount'
    ],
    toolbar: mobileOptimized ? 
      'styles | bold italic | bullist numlist | link image' :
      'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist | link image media | code | customfullscreen',
    skin: darkMode ? 'oxide-dark' : 'oxide',
    content_css: darkMode ? 'dark' : 'default',
    placeholder: placeholder || 'Start writing...',
    images_upload_handler: handleFileUpload,
    paste_data_images: true,
    branding: false,
    promotion: false,
    
    // Fix z-index stacking in fullscreen
    toolbar_sticky: true,
    toolbar_sticky_offset: isFullscreenMode ? 60 : 0,
    toolbar_location: 'top',
    
    // Security configurations
    content_security_policy: "script-src 'self' 'unsafe-inline' 'unsafe-eval'; frame-src 'self' https://www.youtube-nocookie.com",
    allow_script_urls: false,
    convert_urls: true,
    relative_urls: false,
    remove_script_host: true,
    
    // Media embed handling
    media_url_resolver: (data, resolve) => {
      const url = data.url;
      let embedHtml = '';

      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        const videoId = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i)?.[1];
        if (videoId) {
          embedHtml = `<div class="video-embed"><iframe src="https://www.youtube-nocookie.com/embed/${videoId}?rel=0&modestbranding=1&disablekb=1&controls=1" frameborder="0" allowfullscreen="allowfullscreen" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>`;
        }
      }
      // Vimeo
      else if (url.includes('vimeo.com')) {
        const videoId = url.match(/vimeo\.com\/(?:video\/)?([0-9]+)/i)?.[1];
        if (videoId) {
          embedHtml = `<div class="video-embed"><iframe src="https://player.vimeo.com/video/${videoId}" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay; fullscreen; picture-in-picture"></iframe></div>`;
        }
      }
      // Twitter
      else if (url.includes('twitter.com') || url.includes('x.com')) {
        embedHtml = `<div class="twitter-embed"><blockquote class="twitter-tweet"><a href="${url}"></a></blockquote><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>`;
      }
      // TikTok
      else if (url.includes('tiktok.com')) {
        embedHtml = `<blockquote class="tiktok-embed" cite="${url}"><a href="${url}"></a></blockquote><script async src="https://www.tiktok.com/embed.js"></script>`;
      }

      resolve({ html: embedHtml });
    },

    // Content preservation settings
    verify_html: false,
    valid_elements: '*[*]',  // Allow all elements and attributes
    extended_valid_elements: '*[*]',  // Allow extended elements
    custom_elements: '~*',  // Allow custom elements
    preserve_cdata: true,
    entity_encoding: 'raw',
    indent: true,
    indent_before: 'p,h1,h2,h3,h4,h5,h6,div,title,style,pre,script,td,ul,li,area,table,thead,tfoot,tbody,tr,section,article,hgroup,aside,figure,header,footer',
    indent_after: 'p,h1,h2,h3,h4,h5,h6,div,title,style,pre,script,td,ul,li,area,table,thead,tfoot,tbody,tr,section,article,hgroup,aside,figure,header,footer',
    
    // Format settings
    formats: {
      alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'left' },
      aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'center' },
      alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', classes: 'right' },
      bold: { inline: 'strong' },
      italic: { inline: 'em' }
    },

    // Style preservation
    style_formats_merge: true,
    style_formats: [
      { title: 'Headers', items: [
        { title: 'Header 1', format: 'h1' },
        { title: 'Header 2', format: 'h2' },
        { title: 'Header 3', format: 'h3' }
      ]},
      { title: 'Inline', items: [
        { title: 'Bold', format: 'bold' },
        { title: 'Italic', format: 'italic' }
      ]}
    ],

    // Cleanup settings
    cleanup: false,
    cleanup_on_startup: false,
    trim_span_elements: false,
    remove_redundant_brs: false,
    force_p_newlines: false,
    force_br_newlines: false,
    forced_root_block: '',
    keep_styles: true,
    remove_trailing_brs: false,

    // Allowed domains for embeds
    valid_iframe_origins: [
      'https://www.youtube.com',
      'https://youtube.com',
      'https://www.youtube-nocookie.com',
      'https://player.vimeo.com',
      'https://www.dailymotion.com',
      'https://twitter.com',
      'https://platform.twitter.com',
      'https://www.instagram.com',
      'https://www.facebook.com',
      'https://player.twitch.tv',
      'https://clips.twitch.tv',
      'https://www.tiktok.com'
    ],

    // Iframe sandbox permissions
    iframe_sandbox_allowed: 'allow-scripts allow-same-origin allow-popups allow-presentation',

    // Additional security measures
    allow_script_urls: false,
    convert_urls: true,
    relative_urls: false,
    remove_script_host: true,

    // Style for embedded content
    content_style: `
      .video-embed {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        height: 0;
        overflow: hidden;
        max-width: 100%;
        margin: 1em 0;
      }
      
      .video-embed iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      
      .twitter-embed,
      .tiktok-embed {
        margin: 1em 0;
        width: 100%;
        max-width: 550px;
      }
    `
  })

  return (
    <div className="relative w-full">
      <div className={`relative ${isFullscreen ? 'hidden' : ''}`}>
        <Editor
          apiKey="r8p3glyuit5cq633zmk5kdurjdeu33ip4dtvwl17ojaei3mh"
          onInit={(evt, editor) => editorRef.current = editor}
          value={url || ''}
          init={getEditorConfig()}
          onEditorChange={(content) => onChange(content)}
        />
        
        {!mobileOptimized && (
          <button
            onClick={() => setIsFullscreen(true)}
            className="absolute top-[-40px] right-0 px-3 py-2 flex items-center gap-2 bg-black/20 hover:bg-black/30 rounded-lg text-white/80 hover:text-white transition-all transform hover:scale-105"
          >
            <Fullscreen sx={{ fontSize: "20px" }} />
            <span className="text-sm font-medium">Go Fullscreen</span>
          </button>
        )}
      </div>

      {isFullscreen && createPortal(
        <div className="fixed inset-0 z-[9998] bg-gradient-to-br from-[#1a1a1a] to-[#2a2a2a]">
          <div className="fixed top-0 left-0 right-0 p-2 bg-gradient-to-b from-black/80 to-transparent z-[9999] flex justify-between items-center">
            <h2 className="text-white/90 text-lg font-medium px-4">Edit Content</h2>
            <button
              onClick={() => setIsFullscreen(false)}
              className="p-2 bg-white/10 hover:bg-white/20 rounded-full text-white/80 hover:text-white transition-all transform hover:scale-105"
            >
              <Close sx={{ fontSize: "20px" }} />
            </button>
          </div>

          <div className="fixed inset-0 pt-16 px-4 pb-4 overflow-y-auto">
            <div className="max-w-6xl mx-auto p-16 pt-24 relative z-[9997]">
              <Editor
                apiKey="r8p3glyuit5cq633zmk5kdurjdeu33ip4dtvwl17ojaei3mh"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                  // Force editor to recalculate positions
                  setTimeout(() => {
                    editor.fire('ResizeEditor');
                  }, 100);
                }}
                value={url || ''}
                init={getEditorConfig(true)}
                onEditorChange={(content) => onChange(content)}
              />
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  )
}

export default CustomEditor